<template>
  <div>
    <a-modal v-model="visible" :destroyOnClose="true" :maskClosable="false" centered @cancel="cancel" @ok="confirm">
      <div slot="title">{{ $t("货品移库") }}</div>
      <div>
        <a-form-model ref="form" :model="dataForm" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="location_out" :label="$t('源库位')">
            <location-search-select v-model="dataForm.location_out" :warehouse="warehouse" @select="selectLocationOut" />
          </a-form-model-item>
          <sub-batch-form-item :location="dataForm.location_out" @select="selectSubBatchOut" />
          <a-form-model-item :label="$t('库存数量')">
            <a-input-number :value="dataForm.remain_quantity" disabled style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="transfer_quantity" :label="$t('移库数量')">
            <a-input-number v-model="dataForm.transfer_quantity" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="location_in" :label="$t('目标库位')">
            <location-search-select v-model="dataForm.location_in" :warehouse="warehouse" @select="selectLocationIn" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rules } from "./rules";

export default {
  components: {
    LocationSearchSelect: () => import("@/components/LocationSearchSelect/"),
    SubBatchFormItem: () => import("@/components/SubBatchFormItem/"),
  },
  props: ["visible", "warehouse"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      dataForm: {},
    };
  },
  methods: {
    selectLocationOut(item) {
      this.dataForm.location_out_number = item.number;
    },
    selectSubBatchOut(item) {
      console.log(item)
      this.dataForm.material_number = item.material_number;
      this.dataForm.material_name = item.material_name;
      this.dataForm.batch_number = item.batch_number;
      this.dataForm.sub_batch_out = item.id;
      this.dataForm.remain_quantity = item.remain_quantity;
      this.dataForm.status_display = item.status_display;
    },
    selectLocationIn(item) {
      this.dataForm.location_in_number = item.number;
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("add", this.dataForm);
          this.cancel();
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.resetForm();
    },
    resetForm() {
      this.dataForm = {
        type: "material_transfer",
        material_number: "",
        material_name: "",
        status_display: "",
        batch_number: "",
        sub_batch_out: undefined,
        location_out: undefined,
        location_out_number: "",
        location_in: undefined,
        location_in_number: "",
        transfer_quantity: 1,
        remain_quantity: 0,
      };
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped></style>
